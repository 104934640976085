<template>
  <div v-click-outside="clearFilterAndClose" class="filter-ddown">
    <dropdown ref="ddown" :full-width="true">
      <template #trigger="{ toggle, active, uuid }">
        <button
          data-testid="select-btn"
          class="select-btn"
          :class="{ 'text-brand-blue-500': selectedIndex > -1 }"
          :aria-expanded="active"
          :aria-controls="uuid"
          @click="toggle"
        >
          <span
            ><icon
              v-if="prefixVector"
              class="select-icon-pre"
              :name="prefixVector"
          /></span>
          <span class="span-desktop">{{ selectedLabel }}</span>
          <span class="span-mobile">{{ selectedLabelMobile }}</span>
          <span> <icon class="select-icon-open" name="IconChevronDown" /></span>
        </button>
      </template>
      <div class="filter-content" style="max-height: 32rem">
        <!-- Filtering (if applicable) -->
        <div v-if="filterable" class="filter-box">
          <input
            v-model="filterString"
            class="filter-input"
            type="text"
            placeholder="Search"
          />
        </div>
        <!-- Values -->
        <ul class="ddown-items">
          <!-- Default option -->
          <li v-if="nullOption" class="ddown-item-default">
            <button
              class="filter-item-default-btn"
              type="button"
              @click="select(null)"
            >
              {{ defaultOption }}
            </button>
          </li>
          <!-- Actual filtered options -->
          <li
            v-for="({ value, label }, i) in filteredOptions"
            :key="i"
            ref="valuesList"
            class="ddown-item"
            :class="{
              'ddown-item-selected': value === selected
            }"
          >
            <hr v-if="value === '------'" class="pointer-events-none" />
            <button
              v-else
              :data-testid="`ddown-item-btn-${i}`"
              class="ddown-item-btn"
              type="button"
              @click="select(value)"
            >
              {{ label }} <span class="sr-only">selected</span>
            </button>
          </li>
        </ul>
      </div>
    </dropdown>
  </div>
</template>
<script setup lang="ts">
// this filter dropdown component goal is to let the parent know what option the user would like to use
// for the following purposes, filtering or sorting
// it has some additional features to add prefixes for mobile view and icons to improve ux
// style wise it has tailwind apply classes to allow for deep selectors at the parent level

// props

interface FilterDropdownProps {
  defaultOption?: string
  filterable?: boolean
  prefixLabel?: string
  prefixVector?: string
  nullOption?: boolean
  selected?: string
  options: Array<{ label: string; value: string } | string>
}

const props = withDefaults(defineProps<FilterDropdownProps>(), {
  defaultOption: 'All',
  filterable: true,
  prefixLabel: '',
  prefixVector: undefined,
  nullOption: true,
  selected: '',
  options: () => [{ value: '', label: '' }]
})

// local references

const ddown = ref<null | { active: boolean; toggle: () => void }>(null)
const filterString = ref('')

// emit events to parents

const emits = defineEmits(['update:selected'])

// computed

const baseOptions = computed<{ label: string; value: string }[]>(() => {
  return props.options.map((v: { label: string; value: string } | string) =>
    typeof v == 'string' ? { label: v, value: v } : v
  )
})
const filteredOptions = computed(() => {
  return baseOptions.value.filter((x: { label: string; value: string }) =>
    x.label.toLowerCase().includes(filterString.value.toLowerCase())
  )
})
const selectedIndex = computed(() => {
  return baseOptions.value.findIndex(
    (o: { label: string; value: string }) => o.value === props.selected
  )
})
const selectedLabel = computed(() => {
  return baseOptions.value[selectedIndex.value]?.label || props.defaultOption
})
const selectedLabelMobile = computed(() => {
  const label =
    baseOptions.value[selectedIndex.value]?.label || props.defaultOption
  return `${props.prefixLabel} ${label}`
})

// methods

const clearFilterAndClose = () => {
  if (filterString.value.length) filterString.value = ''
  if (ddown.value && ddown.value.active) ddown.value.toggle()
}

const select = (value: string | null) => {
  emits('update:selected', value || '')
  clearFilterAndClose()
}
</script>
<style scoped lang="postcss">
/* defaults */
.ddown-item-default {
  @apply px-4 pt-3;
}
.ddown-items {
  @apply p-0 max-h-72;
}
.ddown-item {
  @apply px-4 py-1 leading-6 hover:bg-int-grey-04;
}
.filter-ddown {
  @apply relative md:text-sm text-int-grey-01 md:border-l border-int-grey-03;
}
.filter-content {
  @apply w-full overflow-y-scroll border border-int-grey-03 md:w-full z-60;
}
.filter-box {
  @apply px-4 py-3 bg-int-off-white;
}
.filter-input {
  @apply w-full px-4 py-3 border shadow-sm border-int-grey-03;
}
.filter-item-default-btn {
  @apply w-full py-2 text-left;
}
.ddown-item-selected {
  @apply bg-int-blue-04;
}
.ddown-item-btn {
  @apply w-full py-2 text-left;
}
.select-btn {
  @apply flex items-center justify-between px-4 py-2 h-14 md:h-16  text-base bg-white border md:border-none border-int-grey-03 md:text-left w-full md:w-32 lg:w-48 focus:outline-none focus:ring focus:ring-inset focus:ring-brand-blue-300;
}
.select-btn-spans {
  @apply border flex items-center gap-2 overflow-hidden;
}
.select-icon-pre {
  @apply text-xl md:hidden p-0.5 mt-1.5 mr-2;
}
.select-icon-open {
  @apply p-0 text-2xl pt-2;
}
.span-mobile {
  @apply text-left md:hidden flex-1 overflow-hidden leading-6;
}
.span-desktop {
  @apply text-left hidden md:block flex-1;
}
</style>
